<template>
    <transition name="dropdown">
        <div ref="row" class="row-container" :class="{ header: this.isHeader }">
            <div class="row" :class="getRowClassName(row)" @click="setFocus">
                <CollapseController
                    v-if="isRecursive && !isLoading"
                    :row="row"
                    :isHeader="isHeader"
                    v-model:isFolded="isFolded"
                    v-model:isCollapsed="isCollapsedValue"
                    @set-fold="foldChildRow"
                />
                <div
                    v-for="(column, colIndex) in tableShowColumns"
                    :key="colIndex"
                    :class="getCellClassName(column, colIndex)"
                    :data-before="column.label"
                    class="cell"
                >
                    <slot v-if="!isLoading" :name="column.key" :row="row">
                        {{ getDataContent(row, column.key) }}
                    </slot>
                    <div
                        v-show="
                            !isLoading &&
                            isHeader &&
                            column?.toolTips?.hasToolTips
                        "
                        class="info-icon"
                        :class="column.key + '-tooltip'"
                        :tooltip="column?.toolTips?.content.desktop"
                        tooltip-touch
                    />
                    <div
                        v-if="!isLoading && shouldShowFilterIcon(column.key)"
                        @click.stop="showFilterModal(column.key)"
                        class="dark-filter-icon red-dot"
                    />
                </div>
            </div>
            <!-- Deep -->
            <Row
                v-for="(rowDetail, rowDetailIndex) in row._detail"
                v-show="!isCollapsedValue"
                :class="{ 'empty-row': rowDetail.isEmptyRow }"
                :data-empty-hint="rowDetail.emptyHint"
                :style="{ '--empty-row-width': rowDetail.rowWidth }"
                :key="rowDetailIndex"
                :row="rowDetail"
                :columns="columns"
                :isLoading="isLoading"
                :isRecursive="isRecursive"
                :deep="deep + 1"
                :isFocusRow="isFocusRow"
                v-model:isCollapsed="childCollapsedList[rowDetailIndex]"
            >
                <template v-for="(_, slot) in $slots" v-slot:[slot]="props">
                    <slot :name="slot" :row="props.row" />
                </template>
            </Row>
        </div>
    </transition>
</template>

<script>
import CollapseController from '@/components/table/CollapseController.vue'

export default {
    name: 'Row',
    emits: [
        'update:isCollapsed',
        'update:headerCollapsedList',
        'update:isShowFilterModal',
        'update:filterColumnKey'
    ],
    components: {
        CollapseController
    },
    props: {
        isHeader: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        row: {
            type: Object,
            default: function () {
                return {}
            }
        },
        tips: {
            type: String,
            default: ''
        },
        columns: {
            type: Array,
            default: function () {
                return []
            }
        },
        isRecursive: {
            type: Boolean,
            default: false
        },
        isCollapsed: {
            type: Boolean,
            default: false
        },
        isFilterSetList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        headerCollapsedList: {
            type: Array,
            default: function () {
                return []
            }
        },
        deep: {
            type: Number,
            default: 0
        },
        isShowFilterModal: {
            type: Boolean,
            default: false
        },
        filterColumnKey: {
            type: String,
            default: ''
        },
        isFocusRow: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getRowClassName: function (row) {
            return {
                ...row.className,
                header: this.isHeader,
                body: !this.isHeader,
                loading: this.isLoading,
                [`deep-${this.deep}`]: this.isRecursive,
                focus: this.isFocus && this.isFocusRow
            }
        },
        getCellClassName: function (column, index) {
            const preColumn = this.tableShowColumns[index - 1]
            const nextColumn = this.tableShowColumns[index + 1]
            const cellClassName = this.$getClassNameFromCamel(column.key)
            const classList = { [cellClassName]: true }
            switch (column.position) {
                case 'right':
                    classList['to-right'] = true
                    break
                case 'left':
                    classList['to-left'] = true
                    break
                case 'center':
                    classList['to-center'] = true
                    break
            }
            if (column.divider) {
                classList['divider-right'] = true
            }
            if (column.isFixed && !this.isLoading) {
                classList['fixed'] = true
                this.setFixedColumnWidth()
            }
            if (column.rowNumber === 1 && nextColumn?.rowNumber === 2) {
                // 若一列資料有兩排，此欄要放在第一排
                classList['first-line'] = true
            } else if (column.rowNumber === 2 && preColumn?.rowNumber === 1) {
                // 若一列資料有兩排，此欄要放在第二排
                classList['second-line'] = true
            } else {
                // 若一列資料有兩排，此欄要向下合併儲存格
                classList['row-span'] = true
            }
            return classList
        },
        getDataContent: function (row, columnKey) {
            if (this.isLoading) return ''
            if (this.isHeader) return row[columnKey]
            const column = this.columns.find((col) => col.key === columnKey)
            const data = row[columnKey]?.value ?? row[columnKey]
            const unit = row[columnKey]?.unit ?? column.unit ?? ''
            let value = data
            if (typeof data === 'number') value = this.$numberWithComma(data)
            if (value && unit) value = `${value} ${unit}`
            return value || '-'
        },
        setFixedColumnWidth: function () {
            this.$nextTick(() => {
                const fixedColumn = this.$refs.row?.querySelector('.fixed')
                const fixedWidth = fixedColumn?.clientWidth || 0
                const fixedLeft = fixedColumn.offsetLeft + fixedWidth
                const parentNode = this.$refs.row.parentNode

                if (fixedLeft > 0) {
                    parentNode.style.setProperty(
                        '--fixed-left',
                        `${fixedLeft}px`
                    )
                }
            })
        },
        foldChildRow: function (isFolded) {
            const childCount = this.isHeader
                ? this.headerCollapsedList.length
                : this.row._detail?.length || 0
            this.childCollapsedList = Array(childCount).fill(isFolded)
        },
        showFilterModal: function (columnKey) {
            const column = this.tableShowColumns.find(
                (column) => column.key === columnKey
            )
            this.filterColumnKeyValue = column.alias
                ? Object.keys(column.alias)[0]
                : columnKey

            this.isShowFilterModalValue = true
        },
        shouldShowFilterIcon: function (columnKey) {
            const aliasColumnKey = this.$getColumnKey(columnKey)
            return (
                this.isHeader &&
                !this.isLoading &&
                this.isFilterSetList[aliasColumnKey]
            )
        },
        setFocus: function () {
            if (this.isLoading) return
            this.isFocus = !this.isFocus
        }
        //test----
        // setRowHeaderHeight: function () {
        //     let rowHeader = this.$refs.row
        //     rowHeader.style.top = 60 + 'px'
        //     rowHeader.style.position = 'fixed'
        // }
    },
    computed: {
        tableShowColumns: function () {
            // this.$reloadTips()
            return this.columns.filter((column) => column.isVisible)
        },
        gridTemplateColumns: function () {
            const controllerWidth = `minmax(40px,1fr)`
            const columnsWidth = this.tableShowColumns
                .filter((col, index) => {
                    const preColumn = this.tableShowColumns[index - 1]
                    // const nextColumn = this.tableShowColumns[index + 1]
                    return col.rowNumber !== 2 || preColumn?.rowNumber !== 1
                })
                .map(
                    (col) =>
                        `minmax(${col.widthPx || 0}px, ${col.width || 0}fr)`
                )
            if (this.isRecursive && !this.isLoading)
                return [controllerWidth, ...columnsWidth].join(' ')
            else return [...columnsWidth].join(' ')
        },
        isCollapsedValue: {
            get() {
                return this.isCollapsed
            },
            set(val) {
                this.$emit('update:isCollapsed', val)
            }
        },
        isShowFilterModalValue: {
            get() {
                return this.isShowFilterModal
            },
            set(val) {
                this.$emit('update:isShowFilterModal', val)
            }
        },
        filterColumnKeyValue: {
            get() {
                return this.filterColumnKey
            },
            set(val) {
                this.$emit('update:filterColumnKey', val)
            }
        },
        detectWebHeaderHeight() {
            // return this.$store.state.webHeaderHeight
            return this.tempScroll
        }
    },
    watch: {
        gridTemplateColumns: {
            handler() {
                this.$nextTick(() => {
                    let parentNode = this.$refs.row.parentNode
                    while (!parentNode.classList.contains('table')) {
                        parentNode = parentNode.parentNode
                    }
                    parentNode.style.setProperty(
                        '--grid-template-columns',
                        this.gridTemplateColumns
                    )
                })
            },
            deep: true,
            immediate: true
        },
        childCollapsedList: {
            handler() {
                // 只要子節點有一個被打開，這層的Fold就變成False
                const list = this.childCollapsedList
                const isAllCollapsed = list.every((isCollapsed) => isCollapsed)
                this.isFolded = isAllCollapsed
                // for Header Control
                this.$emit(
                    'update:headerCollapsedList',
                    this.childCollapsedList
                )
            },
            deep: true
        },
        row: {
            handler() {
                this.isFocus = false
            },
            deep: true
        },
        detectWebHeaderHeight(newPosition, oldPosition) {
            console.log(`watching ${oldPosition} ${newPosition}`)
            // this.setRowHeaderHeight()
        },
        isLoading: function () {
            if (!this.isLoading) this.$reloadTips()
        }
    },
    data() {
        return {
            isFolded: false,
            childCollapsedList: this.headerCollapsedList,
            focusList: [],
            isFocus: false,
            tempScroll: 0
        }
    },
    mounted() {
        //如果是header再執行
        if (this.isHeader) {
            // this.setRowHeaderHeight()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/common.scss';
.info-icon {
    width: 14px;
    height: 14px;
    margin-left: 5px;
}
.row {
    &.loading {
        &.header .cell:after {
            content: '' !important;
            height: 10px !important;
            @extend .animated-background;
        }
        &.body .cell:before {
            content: none !important;
            height: 20px !important;
            @extend .animated-background;
        }
        &.body .cell:after {
            content: '' !important;
            height: 20px !important;
            @extend .animated-background;
        }
        .divider-right:after {
            content: '' !important;
            position: unset !important;
            border-right: none !important;
        }
        @media screen and (min-width: 577px) {
            &.body .first-line.cell:before,
            &.body .second-line.cell:before {
                height: 10px !important;
            }
            &.body .first-line.cell:after,
            &.body .second-line.cell:after {
                height: 10px !important;
            }
        }
    }
    position: relative;
}

.row-container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    flex-wrap: wrap;

    &:not(.header):not(:first-of-type) {
        .row {
            border-top: 1px solid $eighth-grey-disabled-light;
        }
    }

    &.header {
        position: sticky;
        // top: 12%;
        background-color: $primary-grey;
        z-index: 9;
        &.fixing {
            max-width: 1182px;
            overflow: hidden;
            position: fixed;
        }

        .info-icon {
            margin-left: 5px;
            width: 14px;
            height: 14px;
        }
    }

    &.empty-row {
        position: relative;
        border-top: 1px solid $eighth-grey-disabled-light;

        &:before {
            content: attr(data-empty-hint);
            display: grid;
            place-items: center;
            width: var(--empty-row-width);
            height: 49px;
            position: sticky;
            top: 0;
            left: 0;
            padding-top: 1px;
            background: $seventh-grey;
            color: $sixth-black-light;
            z-index: 1;
            font-size: 14px;
        }

        .row {
            display: none;
        }
    }

    .row {
        display: grid;
        grid-template-columns: var(--grid-template-columns);
        grid-template-rows: auto auto;
        grid-auto-flow: column;
        align-items: center;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 14px;
        letter-spacing: 0.4px;
        border-spacing: 0;

        &.header {
            min-height: 40px;
            background-color: $primary-grey;
            color: $sixth-black;
            font-weight: 600;
            white-space: pre-line;

            .cell {
                word-break: keep-all;
                background-color: $primary-grey;
            }

            .dark-filter-icon {
                min-width: 12px;
                min-height: 12px;
                max-width: 12px;
                max-height: 12px;
                margin-left: 4px;
                cursor: pointer;
                &.red-dot:after {
                    content: ' ';
                    display: block;
                    margin: -4px 0 0 14px;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: $primary-red;
                }
            }
        }

        &.body {
            min-height: 50px;
            padding: 0 20px;
            background-color: $primary-white;

            .cell {
                word-break: break-all;
                background-color: inherit;
            }
            .name {
                @media screen and(max-width:576px) {
                    text-align: start;
                }
            }

            &.focus:not(.sum-row) {
                position: relative;
                background-color: $fifth-blue;
                &::before {
                    pointer-events: none;
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 3;
                    top: 0;
                    box-shadow: 0px 0px 0px 1px $third-blue inset;
                }
            }
        }

        .cell {
            display: flex;
            height: 100%;
            padding: 0 10px;
            box-sizing: border-box;

            &.first-line {
                padding-top: 5px;
            }

            &.second-line {
                padding-bottom: 5px;
            }

            &.row-span {
                grid-row: span 2;
            }
        }

        // 垂直分隔線
        .divider-right {
            position: relative;
            &:after {
                content: '';
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                border-right: 2px solid $eighth-grey-disabled-light;
            }
            &.padding:after {
                right: -10px;
            }
        }

        // 鎖定欄位
        .fixed {
            padding-right: 0;
            position: sticky;
            left: 0;
            z-index: 2;

            /* SHADOW */
            &:before,
            &:after {
                content: '';
                position: sticky;
                display: block;
                height: 100%;
                left: calc(var(--fixed-left) - 12px);
            }
            &:before {
                width: 3px;
                box-shadow: 3px 0px 3px rgba(179, 179, 179, 0.15);
            }

            /* COVER */
            &:after {
                width: 10px;
                background: inherit;
                z-index: 1;
            }
        }
    }
}

.card {
    @media screen and (max-width: 576px) {
        .row-container {
            width: 100%;
            padding: 3px 3px;
            box-sizing: border-box;

            &:not(.header):not(:first-of-type) {
                border-top: none;
            }

            &.header {
                display: none;
            }

            .row {
                padding: 10px 15px;
                box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
                border-radius: 3px;
                margin-bottom: 10px;

                &.body {
                    display: flex;
                    flex-wrap: wrap;
                    min-height: unset;
                    &:not(.sum-row) {
                        background-color: $primary-white;
                    }

                    &.loading {
                        .cell:before {
                            content: '' !important;
                            width: 20%;
                        }
                        .cell:after {
                            width: 70%;
                        }
                    }

                    .cell {
                        justify-content: space-between;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        margin: 3px 0;
                        font-size: 14px;
                        color: $sixth-black;
                        word-break: unset;
                        background-color: unset;

                        &:before {
                            content: attr(data-before);
                            display: block;
                            margin-right: 5px;
                            word-break: keep-all;
                            white-space: nowrap;
                            color: $placeholder-black;
                        }
                    }
                }

                .cell {
                    display: flex;
                    width: 100%;
                    height: unset;
                    padding: 0;
                }

                // 垂直分隔線
                .divider-right {
                    position: unset;
                    padding-bottom: 10px;
                    border-bottom: 1px dashed $fourth-grey;
                    &:after {
                        content: unset;
                    }
                    &.padding:after {
                        right: unset;
                    }
                }

                // 鎖定欄位
                .fixed {
                    padding-right: unset;
                    position: unset;
                    border-top: unset;
                    margin-top: unset;
                    box-sizing: unset;
                    z-index: unset;

                    /* SHADOW */
                    &:before,
                    &:after {
                        content: unset;
                        left: unset;
                        height: unset;
                        width: unset;
                        box-shadow: unset;
                    }
                }
            }
        }
    }
}
</style>
